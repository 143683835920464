import { WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { DEFAULT_TARGET_CHAIN } from '@shared/constants';

const getChainRequirements = (
  targetChain: WagmiConfigChain = DEFAULT_TARGET_CHAIN,
  requiredSqrAmount?: number | string,
) => ({
  chain: `Ensure you are using the "${targetChain.name}" network.`,
  // TODO: Somehow check gas price for any chain later
  nativeBalance: 'Your wallet must have at least 0.0003 BNB for transaction gas fees.',
  ...(requiredSqrAmount
    ? {
        sqrBalance: `Insufficient SQR balance. Should not be less than ${requiredSqrAmount}.`,
      }
    : {}),
});

export default getChainRequirements;
