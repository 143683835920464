'use client';

import { usePathname } from 'next/navigation';

import { ReactNode, useEffect, useMemo } from 'react';

import Cookies from 'js-cookie';

import { removeAuthUserQueries, useCurrentUserQuery } from '@query';

import { DeserializedUser, LaunchpadUserInfo } from '@api';

import { logout } from '@shared/api';
import { getQueryClient } from '@shared/common/providers/ReactQuery/QueryProviderSsr';
import { storageKeys } from '@shared/constants';
import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';
import { useGetLaunchpadCurrentUserQuery } from '@shared/hooks/query/launchpad';

type ContextValue = {
  isAuth: boolean;
  isLoading: boolean;
  logout: (callback?: () => void) => void;
  launchpadUser?: LaunchpadUserInfo;
  refetchLaunchpadUser: () => void;
  refetchUser: () => void;
} & (
  | {
      isAuth: true;
      user: DeserializedUser;
    }
  | {
      isAuth: false;
      user: undefined | null;
    }
);

const Context = createSafeContext<ContextValue>();

export const useAuth = () => useSafeContext(Context);

export const useAuthUser = () => {
  const { data: user } = useCurrentUserQuery({
    enabled: false,
  });

  return user;
};

interface AuthProviderProps {
  children?: ReactNode;
  onLogout?: () => void;
}

export const logoutClient = (callback?: () => void) => {
  const queryClient = getQueryClient();

  if (queryClient) {
    removeAuthUserQueries(queryClient);
  }

  logout();

  // we should clear cookies after we clear quires data, to prevent logoutClient function to be called twice
  // because we subscribe window.cookieStore on change event and call logoutClient again
  Cookies.remove('Authorized');
  Cookies.remove(storageKeys.LOGGED_INTO_MAGIC_BOOST);
  clearSavedConnectionDetails();

  callback?.();
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const pathname = usePathname();

  const {
    data: user,
    isLoading,
    refetch: refetchUser,
  } = useCurrentUserQuery({
    enabled: !!Cookies.get('Authorized'),
  });

  const isAuth = !!user;

  const {
    data: launchpadUser,
    isLoading: isKYCLoading,
    refetch: refetchLaunchpadUser,
  } = useGetLaunchpadCurrentUserQuery({
    enabled: isAuth,
  });

  // NOTE: We need to use 'launchpadUser' instead of magicId 'user' when it comes to 'wallet addresses'
  // because there is a corner case when user can remove address in 'magic.store' while CANNOT in 'launchpad',
  // and it fixed in DataBase after registration
  const value = useMemo(() => {
    return {
      isLoading: isLoading || isKYCLoading,
      isAuth,
      user: isAuth ? user : undefined,
      launchpadUser,
      refetchLaunchpadUser,
      refetchUser,
      logout: logoutClient,
    } as ContextValue;
  }, [isAuth, user, isLoading, isKYCLoading, launchpadUser, refetchLaunchpadUser, refetchUser]);

  // useListenAccountChange();

  // TODO: come up with better solution
  useEffect(() => {
    window.__logoutClient = logoutClient;
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    if (!Cookies.get('Authorized')) {
      logoutClient();

      return;
    }

    const handleCookieStoreChange = (event: { deleted: { name: string }[] }) => {
      const deletedAuthorizedCookie = event.deleted.find(
        (deleted) => deleted.name === 'Authorized',
      );

      if (deletedAuthorizedCookie) {
        logoutClient();
      }
    };

    window?.cookieStore?.addEventListener('change', handleCookieStoreChange);

    return () => {
      window?.cookieStore?.removeEventListener('change', handleCookieStoreChange);
    };
  }, [isAuth, pathname]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default AuthProvider;
