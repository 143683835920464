import { ContractTransaction, ethers } from 'ethers';

import { WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { SQRClaim } from '@shared/config/contracts/abi-typed';
import { namedWeb3Errors, SQR_CLAIM_CONTRACT } from '@shared/constants';
import displayWalletErrorToast from '@shared/helpers/displayWalletErrorToast';

/**
 * Checks if the EVM extension is enabled.
 */
const isEvmExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined');
};

/**
 * Checks if the Trust wallet browser extension is enabled.
 */
const isTrustWalletExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined' && window.ethereum.isTrust);
};

/**
 * Checks if the Metamask wallet browser extension is enabled.
 */
const isMetamaskExtensionActive = (): boolean => {
  return Boolean(typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask);
};

/**
 * Checks if the OKX wallet browser extension is enabled.
 */
const isOKXWalletExtensionActive = (): boolean => {
  return Boolean(typeof window.okxwallet !== 'undefined');
};

export type EthereumProvider = Record<string, unknown>;

/**
 * Gets the Ethereum provider if EVM[EthereumVirtualMachine] wallet (e.g. Metamask) is installed.
 */
const getEthereumProvider = (): EthereumProvider | undefined => {
  return window.ethereum;
};

const claimSig = async (props: {
  targetChain: WagmiConfigChain;
  account: string;
  amount: number;
  transactionId: string;
  timestampLimit: number;
  signature: string;
}): Promise<ContractTransaction | undefined> => {
  const {
    // targetChain,
    account,
    amount,
    transactionId,
    timestampLimit,
    signature,
  } = props ?? {};

  try {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum as any);
      const signer = provider.getSigner();

      // TODO: Add targetChain check just like with prepareWriteContractProps has
      const sqrClaimContract = new ethers.Contract(
        SQR_CLAIM_CONTRACT.address,
        SQR_CLAIM_CONTRACT.abi,
        signer,
      ) as unknown as SQRClaim;

      return await sqrClaimContract.claimSig(
        account,
        amount,
        transactionId,
        timestampLimit,
        signature,
        { gasLimit: 200_000 },
      );
    }
  } catch (error) {
    displayWalletErrorToast({
      error,
      unknownExceptionReplaceMessage: namedWeb3Errors.UNKNOWN_TRANSACTION_ERROR,
    });
  }
};

const evm = {
  isEvmExtensionActive,
  isTrustWalletExtensionActive,
  isOKXWalletExtensionActive,
  isMetamaskExtensionActive,
  getProvider: getEthereumProvider,
  claimSig,
};

export default evm;
